
interface AppConfig {
  baseUrl: string;
  secretKey: string;
  allowUrls: Array<string>
}

const config: AppConfig = {
  baseUrl: process.env.REACT_APP_BASE_URL as string,
  secretKey: process.env?.REACT_APP_SECRET_SALT as string,
  allowUrls: process.env?.REACT_APP_TRUSTED_URLs?.split(", ") as Array<string>,
};


export default config;
