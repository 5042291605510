/* eslint-disable react-hooks/exhaustive-deps */

import { useAppDispatch } from "app/hooks";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { IErrorResponse } from "types/redux";
import { getAccountDetails, getProfile } from "features/main/profile/slice";
import { useNavigate } from "react-router-dom";

const UseProfileModel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetProfile = () => {
    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
    };

    dispatch(getProfile({ onFailure }));
  };

  const handleGetAccountDetails = () => {
    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
      localStorage.clear();
      navigate("auth/login");
    };

    dispatch(getAccountDetails({ onFailure }));
  };

  useEffect(() => {
    handleGetProfile();
    handleGetAccountDetails();
  }, []);

  return {};
};

export default UseProfileModel;
