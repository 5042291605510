import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
} from "react";
import { Box, Text } from "@chakra-ui/react";
import DOMPurify from "dompurify"; // Import DOMPurify

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  value: string | any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void | any; // Optional for Formik's setFieldValue
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  Icon?: JSX.Element;
  type?: HTMLInputTypeAttribute;
  textLength?: number;
  isRequired?: boolean;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
}

const TextInput = ({
  name,
  value,
  label,
  onChange,
  setFieldValue,
  disabled,
  error,
  isRequired,
  leading,
  trailing,
  type = "text",
  ...props
}: TextInputProps) => {
  // Handler to sanitize and either use Formik's setFieldValue or standard onChange
  const handleSanitizedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value); // Always sanitize input

    if (setFieldValue) {
      // If Formik's setFieldValue is provided, use it
      setFieldValue(name, sanitizedValue);
    } else {
      // Otherwise, call the regular onChange
      onChange(e);
    }
  };

  return (
    <div>
      <Box
        backgroundColor={error ? "#FBEDF3" : disabled ? "#F4F4F4" : "#F7F7F8"}
        border={`1px solid ${error ? "#E53E3E" : "#E2E8F0"}`}
        alignItems="center"
        position="relative"
        borderRadius="0.375rem"
        display="flex"
        gap="2px"
        width={"100%"}
        flexDirection="column"
      >
        <input
          className="input"
          data-peer
          placeholder=" "
          disabled={disabled}
          name={name}
          value={value}
          onChange={handleSanitizedChange} // Use the sanitized change handler
          type={type}
          {...props}
        />
        {label ? (
          <label className="inputLabel">
            {label}
            <span>{isRequired && "*"}</span>
          </label>
        ) : null}
        {leading && <Box className="inputLeading">{leading}</Box>}
        {trailing && <Box className="inputTrailing">{trailing}</Box>}
      </Box>
      {error ? (
        <Text fontSize="12px" color={"red.500"} mt={1}>
          {error}
        </Text>
      ) : null}
    </div>
  );
};

export default TextInput;