import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import {
  IDeleteUser,
  IApproveOrRejectUser,
  IRestrictUser,
  IUpdateUserDetails,
  IUserDetails,
  IUsers,
  IUserSearchParams,
} from "types/user";

export interface IUsersResponse extends ApiResponse {
  data: IUsers;
}

export interface IFetchUsersArgs extends ReduxAction {
  page: number;
  queries: IUserSearchParams;
}

export interface IFetchSingleUserResponse extends ApiResponse {
  data: IUserDetails;
}

export interface IFetchSingleUserArgs extends ReduxAction {
  id: number;
}

export interface IRestrictUserArgs extends ReduxAction {
  payload: IRestrictUser;
  userId: number;
}

export interface IUpdateUserDetailsArgs extends ReduxAction {
  payload: IUpdateUserDetails;
  userId: number;
}

export interface IUpdateUserResponse extends ApiResponse {
  data: IUserDetails;
}

export interface IApproveOrRejectUserArgs extends ReduxAction {
  payload: IApproveOrRejectUser;
  userId: number;
}

export interface IDeleteUserArgs extends ReduxAction {
  payload: IDeleteUser;
  userId: number;
}

export const fetchUsers = createAsyncThunk(
  "/users/fetchUsers",
  async (
    { page, queries, onFailure }: IFetchUsersArgs,
    { rejectWithValue }
  ) => {
    try {
      const searchParams = new URLSearchParams();

      if (Object.values(queries)?.length) {
        Object.entries(queries).forEach(([key, value]) => {
          if (value) {
            searchParams.append(`${key}`, value);
          }
        });
      }

      const { data }: IUsersResponse = await axios.get(`/users?page=${page}`, {
        params: searchParams,
      });

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const fetchSingleUser = createAsyncThunk(
  "/users/fetchSingleUser",
  async ({ id, onFailure }: IFetchSingleUserArgs, { rejectWithValue }) => {
    try {
      const { data }: IFetchSingleUserResponse = await axios.get(
        `/users/${id}`
      );

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const restrictUser = createAsyncThunk(
  "/users/restrictUser",
  async (
    { payload, userId, onSuccess, onFailure }: IRestrictUserArgs,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.post(
        `/restrict/${userId}`,
        payload
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const approveOrRejectUser = createAsyncThunk(
  "/users/approveOrRejectUser",
  async (
    { payload, userId, onSuccess, onFailure }: IApproveOrRejectUserArgs,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.post(
        `/users/${userId}/manual-review`,
        payload
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const updateBvnStatus = createAsyncThunk(
  "/users/updateBvnStatus",
  async (
    { payload, onSuccess, onFailure }: any,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.post(
        // `/user/update-bvn-status/${userId}`,
        '/user/update-bvn-status',
        payload
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "/users/updateUserDetails",
  async (
    { payload, userId, onSuccess, onFailure }: IUpdateUserDetailsArgs,
    { rejectWithValue }
  ) => {
    try {
      const { data }: IUpdateUserResponse = await axios.put(
        `/users/${userId}`,
        payload
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  "/users/deleteUserAccount",
  async (
    { payload, userId, onSuccess, onFailure }: IDeleteUserArgs,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.delete(`/users/${userId}`, {
        data: payload,
      });
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
