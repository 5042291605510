import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import {
  ICharity,
  ICharityDonorsList,
  ICharityList,
  ICreateCharity,
} from "types/charity";
export interface IFetchCharitiesResponse extends ApiResponse {
  data: ICharity;
}

export interface IFetchCharityDonorsResponse extends ApiResponse {
  data: ICharityDonorsList[];
}

export interface ICreateCharityResponse extends ApiResponse {
  data: ICharity;
}

export interface IFetchCharityDonorsArgs extends ReduxAction {
  id: number;
}

export interface ICreateNewCharityArgs extends ReduxAction {
  payload: ICreateCharity;
  isUpdating: boolean;
}

export interface IEnableCharityArgs extends ReduxAction {
  payload: ICharityList;
}

export interface IDeleteCharityArgs extends ReduxAction {
  payload: number;
}

export interface IDeleteCharityResponse extends ReduxAction {
  data: string;
}

export interface IFetchCharityArgs extends ReduxAction {
  page: number;
}

export const fetchCharities = createAsyncThunk(
  "/charity/fetchCharities",
  async ({ page, onFailure }: IFetchCharityArgs, { rejectWithValue }) => {
    try {
      const { data }: IFetchCharitiesResponse = await axios.get(
        `/charities?page=${page}`
      );

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const fetchCharityDonors = createAsyncThunk(
  "/charity/fetchCharityDonors",
  async ({ id, onFailure }: IFetchCharityDonorsArgs, { rejectWithValue }) => {
    try {
      const { data }: any = await axios.get(
        `/charity/${id}/donors`
      );

      return data?.donors;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const createNewCharity = createAsyncThunk(
  "/charity/createNewCharity",
  async (
    { payload, isUpdating, onSuccess, onFailure }: ICreateNewCharityArgs,
    { rejectWithValue }
  ) => {
    const newPayload = { ...payload };
    delete newPayload.id;
    const formData = new FormData();
    for (const [key, value] of Object.entries(newPayload)) {
      if (value) {
        formData.append(`${key}`, value as unknown as any);
      }
    }

    try {
      const apiEndpoint = isUpdating ? `/charity/${payload.id}` : `/charity/`;
      // const { data }: ICreateCharityResponse = isUpdating
      //   ? await axios.put(apiEndpoint, formData)
      //   : await axios.post(apiEndpoint, formData);

      const { data }: ICreateCharityResponse = await axios.post(apiEndpoint, formData);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const enableCharity = createAsyncThunk(
  "/charity/enableCharity",
  async (
    { payload, onSuccess, onFailure }: IEnableCharityArgs,
    { rejectWithValue }
  ) => {
    try {
      const apiEndpoint = `/charity/${payload.id}`;
      const { data }: ICreateCharityResponse = await axios.post(
        apiEndpoint,
        payload
      );

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const deleteCharity = createAsyncThunk(
  "/charity/deleteCharity",
  async (
    { payload, onSuccess, onFailure }: IDeleteCharityArgs,
    { rejectWithValue }
  ) => {
    try {
      const apiEndpoint = `/charity/${payload}`;
      const { data }: IDeleteCharityResponse = await axios.delete(apiEndpoint);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
