/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { IErrorResponse } from "types/redux";
import {decryptData} from "./encryption";
import appConfig from "./config";

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "*/*",
};

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
}

class HttpConfig {
  private serviceBaseUrl: string;

  constructor(url: string) {
    this.serviceBaseUrl = url;
  }

  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const localInstance = axios.create({
      baseURL: this.serviceBaseUrl,
      headers: {
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        ...headers,
      },
    });

    localInstance.interceptors.request.use((config) => {
      const token: string | null = localStorage.getItem("token");
      if (token) {
        let decrypted_token = decryptData(token, appConfig.secretKey);
        config.headers["Authorization"] = `Bearer ${decrypted_token}`;
      }
      return config;
    });

    localInstance.interceptors.response.use(
      (response) => {
        const data = {
          ...response.data,
        } as AxiosResponse;

        return data;
      },
      (error: AxiosError) => {
        const errorResponse = error?.response?.data;
        return this.handleError(errorResponse as IErrorResponse);
      }
    );
    this.instance = localInstance;
    return localInstance;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  private handleError(error: IErrorResponse) {
    if (error.status === StatusCode.Unauthorized || error.status === StatusCode.Forbidden) {
      localStorage.clear();
      window.location.href = "/auth/login";
    }

    return Promise.reject(error);
  }
}

export default HttpConfig;
