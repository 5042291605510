import { Box, Text, Image, Flex, SlideFade } from "@chakra-ui/react";
import LogoMedium from "assets/svg/logo-medium";
import SideNavCollapseIcon from "assets/svg/side-nav-collapse-icon";
import { useState } from "react";
import { navData } from "./navData";
import { useLocation, useNavigate } from "react-router-dom";
import LogoSmall from "assets/svg/logo-small";
import AvatarSmallIcon from "assets/svg/avatar-small";
import ChevronDownIcon from "assets/svg/chevron-down";
import BadgeIcon from "assets/svg/badge";
import NewAdminModal from "components/Modal/Admin/NewAdminModal";
import { IProfile } from "types/profile";
import { isJustSuperAdmin } from "utils";
import { AdminRole } from "types/user";
import UseInviteNewAdminModel from "models/main/useInviteNewAdminModel";
import UseProfileModel from "models/main/useProfileModel";
// import { useAppSelector } from "app/hooks";

const SideNav = () => {
  const route = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const {} = UseProfileModel();
  const { showInviteAdminModal, setShowInviteAdminModal } = UseInviteNewAdminModel();
  // const { accountDetails } = useAppSelector((state) => state.profile);

  const isActive = (value: string) => route.pathname.includes(value);
  const user = localStorage.getItem("user");
  let parsedUser;
  if (user) {
    parsedUser = JSON.parse(user) as IProfile;
  }

  const handleLogout = () => {
    localStorage.clear();
    navigate("auth/login");
  };

  return (
    <Box
      height="100vh"
      overflowY="scroll"
      paddingTop="10px"
      position="relative"
      px={5}
      backgroundColor="#00473c"
      className={isOpen ? "sidenav" : "sidenavClosed"}
    >
      <Box
        paddingRight={isOpen ? "20px" : "0"}
        className="menuBtn"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SideNavCollapseIcon />
      </Box>
      <Box mt={4}>{isOpen ? <LogoMedium /> : <LogoSmall />}</Box>
      <Box mt={10} display="flex" flexDirection="column" gap={8}>
        {navData.map((item) => {
          return (
            <Box
              key={`${Math.random()}`}
              display="flex"
              alignItems="center"
              gap={2}
              role="button"
              onClick={() => navigate(item.link)}
            >
              <Image
                src={item.icon}
                alt={item.text}
                opacity={isActive(item.link) ? "" : "0.7"}
              />
              {isOpen ? (
                <Text
                  color={isActive(item.link) ? "#fff" : "#F7F7F8"}
                  opacity={isActive(item.link) ? "" : "0.7"}
                  cursor="pointer"
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={"24px"}
                >
                  {item.text}
                </Text>
              ) : null}
            </Box>
          );
        })}
      </Box>

      <Box
        position="absolute"
        bottom="50px"
        width="80%"
        zIndex="99"
        backgroundColor={isOpen ? "#00473c" : "transparent"}
        py={isOpen ? 5 : 0}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          role="button"
          cursor="pointer"
          onClick={() => setIsToggled(!isToggled)}
        >
          <Flex gap={4} alignItems="center">
            <AvatarSmallIcon />
            {isOpen ? (
              <Box display="flex" flexDirection="column" gap={1}>
                <Text fontSize={14} color="#fff" fontWeight={500}>
                  {parsedUser?.name}
                </Text>
                <Flex alignItems="center" gap={1}>
                  <Text
                    fontSize={12}
                    color="#F7F7F8"
                    fontWeight={500}
                    opacity=".7"
                    textTransform="capitalize"
                  >
                    {parsedUser?.role}
                  </Text>
                  <BadgeIcon />
                </Flex>
              </Box>
            ) : null}
          </Flex>
          {isOpen ? (
            <Box>
              <ChevronDownIcon />
            </Box>
          ) : null}
        </Flex>

        {/* Toggle */}
        {isToggled && isOpen ? (
          <SlideFade offsetY="20px" in={isToggled}>
            <Box display="flex" flexDirection="column" gap={5} mt={5}>
              <Box borderBottom="1px" paddingBottom={"12px"} borderColor="#fff">
                <Text
                  fontSize={14}
                  color="#F7F7F8"
                  fontWeight={400}
                  role="button"
                  onClick={() => navigate("/main/profile")}
                >
                  Profile
                </Text>
              </Box>
              {isJustSuperAdmin(parsedUser?.role as AdminRole) ? (
                <Box
                  borderBottom="1px"
                  paddingBottom={"12px"}
                  borderColor="#fff"
                >
                  <Text
                    fontSize={14}
                    color="#F7F7F8"
                    fontWeight={500}
                    role="button"
                    cursor="pointer"
                    onClick={() => setShowInviteAdminModal(true)}
                  >
                    Add new admin
                  </Text>
                </Box>
              ) : null}

              {isJustSuperAdmin(parsedUser?.role as AdminRole) ? (
                <Box
                  borderBottom="1px"
                  paddingBottom={"12px"}
                  borderColor="#fff"
                >
                  <Text
                    fontSize={14}
                    color="#F7F7F8"
                    fontWeight={500}
                    role="button"
                    cursor="pointer"
                    onClick={() => navigate("/main/admin-users")}
                  >
                    Admin users
                  </Text>
                </Box>
              ) : null}

              <Box
                borderBottom="1px"
                paddingBottom={"12px"}
                borderColor="#fff"
                cursor="pointer"
                role="button"
                onClick={handleLogout}
              >
                <Text fontSize={14} color="#F09385" fontWeight={400}>
                  Log out
                </Text>
              </Box>
            </Box>
          </SlideFade>
        ) : null}
      </Box>
      {showInviteAdminModal && (
        <NewAdminModal
          isOpen={showInviteAdminModal}
          onClose={() => setShowInviteAdminModal(false)}
        />
      )}
    </Box>
  );
};

export default SideNav;
